import { IconButton } from "@g3r-developers/g3-common";
import Camera from "Components/Camera/Camera";
import sections from "Constants/Sections";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import { InspectionType } from "Types/Enums/InspectionType";
import { MediaType } from "Types/Enums/MediaType";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { QuestionGroupModel } from "Types/InspectionStaging/QuestionGroupModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { LightQuestion } from "./LightQuestion";
import { QuestionCheckbox } from "./QuestionCheckbox";
import QuestionDatePicker from "./QuestionDatePicker";
import { QuestionDropdown } from "./QuestionDropdown";
import QuestionNumericTextBox from "./QuestionNumericTextBox";
import { QuestionToggle } from "./QuestionToggle";

export function QuestionComponent({
    inspectionId,
    question,
    handleChange,
    md,
}: {
    inspectionId?: string;
    question: QuestionAnswerModel;
    handleChange(
        questionId: string,
        value: string,
        textValue: string,
        asset?: string,
        assetMediaType?: string,
        assetOnlyUpdate?: boolean
    ): void;
    md: 1 | 2 | 3 | 4 | 6 | 12;
}) {
    const [inspectionDetails, setInspectionDetails] = useState<OfflineInspectionDetailModel>(undefined);
    const [inspectionSections, setInspectionSections] = useState<QuestionGroupModel[]>(undefined);
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const [photoCaptured, setPhotoCaptured] = useState<boolean>(false);

    const toggleCamera = useCallback(() => setShowCamera(p => !p), []);
    const togglePhotoCaptured = useCallback(() => setPhotoCaptured(p => !p), []);

    const questionComponent = useCallback(() => {
        switch (question?.questionType?.reference) {
            case "Toggle": {
                return (
                    <QuestionToggle
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "InvertedToggle": {
                return (
                    <QuestionToggle
                        inverted
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "MultiList":
            case "ComboBox": {
                return (
                    <QuestionDropdown
                        onChange={val => handleChange(question.id, val?.value, val?.label)}
                        question={question}
                    />
                );
            }
            case "NumericTextBox": {
                return (
                    <QuestionNumericTextBox
                        className="p-3"
                        onChange={val => handleChange(question.id, val?.toString(), val?.toString(), question.asset)}
                        question={question}
                    />
                );
            }
            case "CheckBox": {
                return (
                    <QuestionCheckbox
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "DatePicker": {
                return <QuestionDatePicker onChange={val => handleChange(question.id, val, val)} question={question} />;
            }
        }
    }, [question, handleChange]);

    const isLight = useMemo(() => {
        return question.questionText.endsWith("Light");
    }, [question.questionText]);

    const showMileageCameraButton = useMemo(() => {
        return (
            inspectionDetails?.inspection?.inspectionTypeId !== InspectionType.Signal &&
            question.id === sections.interior.questions.mileage.id
        );
    }, [inspectionDetails?.inspection?.inspectionTypeId, question.id]);

    const showChargePortConditionCameraButton = useMemo(() => {
        return (
            inspectionDetails?.inspection?.inspectionTypeId !== InspectionType.Signal &&
            question.id === sections.ev.questions.chargePortCondition.id
        );
    }, [inspectionDetails?.inspection?.inspectionTypeId, question.id]);

    const showDomesticChargeCameraButton = useMemo(() => {
        return (
            inspectionDetails?.inspection?.inspectionTypeId !== InspectionType.Signal &&
            question.id === sections.ev.questions.domestic3PinCharger.id &&
            question.answer?.toString() === "F1F717B4-AE13-4822-8793-F643029A5FB4"
        );
    }, [inspectionDetails?.inspection?.inspectionTypeId, question.id, question.answer]);

    const showTypeTwoChargerCameraButton = useMemo(() => {
        return (
            inspectionDetails?.inspection?.inspectionTypeId !== InspectionType.Signal &&
            question.id === sections.ev.questions.type2Charger.id &&
            question.answer?.toString() === "ACA9D68B-D49B-487C-803E-3366A22A2F23"
        );
    }, [inspectionDetails?.inspection?.inspectionTypeId, question.id, question.answer]);

    const handlePhotoCaptured = useCallback(
        (img: string) => {
            toggleCamera();
            togglePhotoCaptured();
            handleChange(question.id, question.answer, question.answer?.toString() ?? "", img, MediaType.Photo, true);
        },
        [handleChange, question.answer, question.id, toggleCamera, togglePhotoCaptured]
    );

    const isPhotoTaken = useMemo(() => {
        if (!inspectionId) {
            return false;
        }

        let sectionId = "";
        let questionId = "";
        switch (question.id) {
            case sections.ev.questions.domestic3PinCharger.id:
                sectionId = sections.ev.id;
                questionId = sections.ev.questions.domestic3PinCharger.id;
                break;
            case sections.ev.questions.chargePortCondition.id:
                sectionId = sections.ev.id;
                questionId = sections.ev.questions.chargePortCondition.id;
                break;
            case sections.ev.questions.type2Charger.id:
                sectionId = sections.ev.id;
                questionId = sections.ev.questions.type2Charger.id;
                break;
            default:
                sectionId = sections.interior.id;
                questionId = sections.interior.questions.mileage.id;
                break;
        }

        const section = inspectionSections?.find(_ => _.questionSectionId === sectionId);
        const questionItem = section?.questionItemModels?.find(_ => _.questionId === questionId);
        if (questionItem?.assetId && questionItem.assetId !== null) {
            return true;
        }
        return photoCaptured;
    }, [inspectionId, inspectionSections, photoCaptured, question.id]);

    useEffect(() => {
        if (inspectionId) {
            inspectionDetailStore.load(inspectionId).then(setInspectionDetails);
            inspectionSectionStore.loadInspectionSections(inspectionId).then(setInspectionSections);
        }
    }, [inspectionId]);

    const getCameraLabel = useMemo(() => {
        switch (question.id) {
            case sections.ev.questions.domestic3PinCharger.id:
                return "Domestic (3 Pin) Charger";
            case sections.ev.questions.chargePortCondition.id:
                return "Charge Port Condition";
            case sections.ev.questions.type2Charger.id:
                return "Type 2 Charger";
            default:
                return "Mileage";
        }
    }, [question.id]);

    const getCameraDescription = useMemo(() => {
        switch (question.id) {
            case sections.ev.questions.domestic3PinCharger.id:
                return "Take a photo of the vehicles Domestic (3 Pin) Charger";
            case sections.ev.questions.chargePortCondition.id:
                return "Take a photo of the vehicles Charge Port";
            case sections.ev.questions.type2Charger.id:
                return "Take a photo of the vehicles Type 2 Charger";
            default:
                return "Take a photo of the mileage reading of the vehicle";
        }
    }, [question.id]);

    return (
        <>
            {!isLight &&
                !showMileageCameraButton &&
                !showChargePortConditionCameraButton &&
                !showDomesticChargeCameraButton &&
                !showTypeTwoChargerCameraButton && (
                    <>
                        {md === 12 && (
                            <>
                                <Col md={6} className="mb-3">
                                    <label>{question.questionText}</label>
                                </Col>
                                <Col md={6} className="mb-3">
                                    {questionComponent()}
                                </Col>
                            </>
                        )}
                        {md < 12 && (
                            <Col className="mb-3">
                                <label>{question.questionText}</label>
                                {questionComponent()}
                            </Col>
                        )}
                    </>
                )}

            {isLight && (
                <>
                    <Col md="12" className="mb-3">
                        <LightQuestion question={question} handleChange={val => handleChange(question.id, val, val)} />
                    </Col>
                </>
            )}

            {(showMileageCameraButton ||
                showChargePortConditionCameraButton ||
                showDomesticChargeCameraButton ||
                showTypeTwoChargerCameraButton) && (
                <>
                    {md === 12 && (
                        <>
                            <Col md={6} className="mb-3">
                                <label>{question.questionText}</label>
                            </Col>
                            <Col md={4} className="mb-3">
                                {questionComponent()}
                            </Col>
                            <Col md={2} className="mb-3">
                                <IconButton
                                    className=" w-100 py-3"
                                    faIconString="fas fa-camera"
                                    onClick={toggleCamera}
                                    variant={isPhotoTaken ? "success" : "danger"}
                                />
                            </Col>
                        </>
                    )}

                    {md < 12 && (
                        <Col className="mb-3">
                            <label>{question.questionText}</label>
                            <Row>
                                <Col md={8}>{questionComponent()}</Col>
                                <Col md={4}>
                                    <IconButton
                                        className=" w-100 py-3"
                                        faIconString="fas fa-camera"
                                        onClick={toggleCamera}
                                        variant={isPhotoTaken ? "success" : "danger"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}

                    {showCamera && (
                        <Modal className="p-0 m-0" isOpen fullscreen={true}>
                            <ModalBody className="p-0">
                                <Camera
                                    description={getCameraDescription}
                                    label={getCameraLabel}
                                    onPhoto={handlePhotoCaptured}
                                    toggleCamera={toggleCamera}
                                />
                            </ModalBody>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
}
